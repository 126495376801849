<template>
  <BModal
    id="modalMap"
    ref="modalMap"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="lg"
    :centered="true"
    @shown="loadAddress({ lat: initialPlaces.lat, lng: initialPlaces.lng })"
  >
    <div class="text-center my-1 d-flex flex-column gap-15">
      <div class="font-bold text-10 text-black">
        Tambah Lokasi
      </div>
      <gmap-map
        style="width: 100%; height: 400px;"
        :zoom="zoom"
        :center="coordinate || coordinateTemp"
        @idle="sync"
        @center_changed="updateCenter"
      >
        <gmap-marker ref="myMarker" :position="google && new google.maps.LatLng(1.38, 103.8)" />
      </gmap-map>
      <div class="d-flex">
        <div class="d-flex flex-column col-md-8 text-left">
          <strong class="text-8 text--dark">{{name}}</strong>
          <span class="text-6 text--dark">{{address}}</span>
        </div>
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <gmap-autocomplete
            class="form-control"
            @place_changed="setPlace"
          />
        </div>
      </div>
      <div class="d-flex gap-12 justify-center col-md-4 align-self-end">
        <button
          size="md"
          variant="outline-primary"
          class="w-100 button-secondary"
          @click="$bvModal.hide('modalMap')"
        >
          Batal
        </button>
        <button
          class="w-100 button-primary"
          @click="selectLocation()"
        >
          Submit
        </button>
      </div>
    </div>
  </BModal>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  max,
  numeric,
} from '@validations'
import { alertError, alertSuccess } from '@toast'
import { gmapApi } from 'vue2-google-maps'

export default {
  components: {
  },
  props: {
    initialPlaces: {
      type: Object,
    },
  },
  computed: {
    google: gmapApi,
  },
  data() {
    return {
      required,
      min,
      max,

      coordinateTemp: {
        lat: -7.281931215395999,
        lng: 109.46630203862301,
      },
      coordinate: null,
      place: null,
      zoom: 12,

      alertError,
      alertSuccess,
      name: '',
      address: '',
    }
  },
  beforeMount() {
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        name: this.name,
        division_id: Number.isInteger(this.divisionId) ? this.divisionId : this.initialDivisionId,
      }

      if (!this.initialName) {
        const url = 'v1/positions/store'
        await komtimAxiosIns
          .post(url, data)
          .then(() => {
            const text = 'Berhasil menambahkan data'
            this.alertSuccess(text)
            this.loadingSubmit = false

            this.$bvModal.hide('modalMap')
            this.$emit('updated')
          })
          .catch(error => {
            this.loadingSubmit = false
            this.alertError(error)

            if (error.response.status === 422) {
              this.submitErrors = Object.fromEntries(
                Object.entries(error.response.data.data).map(
                  ([key, value]) => [key, value[0]],
                ),
              )
            }
          })
      } else {
        const url = `v1/positions/${this.idItem}/update`
        await komtimAxiosIns
          .put(url, data)
          .then(() => {
            const text = 'Berhasil mengubah data'
            this.alertSuccess(text)
            this.loadingSubmit = false

            this.$bvModal.hide('modalMap')
            this.$emit('updated')
          })
          .catch(error => {
            this.loadingSubmit = false
            this.alertError(error)

            if (error.response.status === 422) {
              this.submitErrors = Object.fromEntries(
                Object.entries(error.response.data.data).map(
                  ([key, value]) => [key, value[0]],
                ),
              )
            }
          })
      }
    },
    updateCenter(latLng) {
      this.place = ''
      this.coordinateTemp = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      }

      this.loadAddress({ lat: latLng.lat(), lng: latLng.lng() })
    },
    sync() {
      this.coordinate = this.coordinateTemp
    },
    setPlace(place) {
      this.place = place
      this.coordinateTemp = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      }

      this.address = place.formatted_address
      this.name = place.name

      this.sync()
      this.zoom = 16
    },
    selectLocation(event) {
      this.latitude = this.coordinate.lat
      this.longitude = this.coordinate.lng

      let addressParts = null
      if (this.place) {
        addressParts = this.place.formatted_address?.split(',')
        this.address = addressParts?.length > 0 ? addressParts[0] : this.address

        const data = {
          lat: this.latitude,
          lng: this.longitude,
          address: this.place.name,
        }

        this.$emit('selected', data)
      } else {
        this.loadAddress()
      }

      this.$nextTick(() => {
        this.$refs.modalMap.toggle()
      })
    },
    loadAddress(param) {
      if (param) {
        this.$geocoder.send({
          lat: param.lat,
          lng: param.lng,
        }, response => {
          const { results } = response
          if (!results.length) return

          const data = results[0]
          const addressParts = data.formatted_address?.split(',')
          this.address = data.formatted_address
          this.name = addressParts?.length > 0 ? addressParts[0] : this.address

          this.coordinateTemp = {
            lat: param.lat,
            lng: param.lng,
          }

          this.sync()
          this.zoom = 16
        })
      } else {
        this.$geocoder.send({
          lat: this.latitude,
          lng: this.longitude,
        }, response => {
          const { results } = response
          if (!results.length) return

          const data = results[0]
          const addressParts = data.formatted_address?.split(',')
          this.address = data.formatted_address
          this.name = addressParts?.length > 0 ? addressParts[0] : this.address

          const payload = {
            lat: this.latitude,
            lng: this.longitude,
            address: addressParts?.length > 0 ? addressParts[0] : this.address,
          }

          this.$emit('selected', payload)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './style/Index.scss';
</style>
<style lang="scss">
.pac-container {
  margin-top: -210px !important;
  background-color: white !important;
  height: 174px !important;
}
</style>
